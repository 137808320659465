import React from 'react'

import Cart from '~/components/Cart'
import { Container } from '~/utils/styles'
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'

const CartContainer = styled(Container)`
  max-width: 80%;
  margin: auto;
  margin-top: 4rem;
  @media (max-width: ${breakpoints.xl}px) {
    max-width: 100%;
  }
`

const CartPage = () => (
  <CartContainer>
    <h1>Cart</h1>
    <Cart />
  </CartContainer>
)

export default CartPage
